import Dispatch from './client';

const urls = {
  delegatedAuthenticate: {
    url: '/users/password-manager/authentication',
    method: 'get',
  },

  logout: {
    url: '/auth/logout',
    method: 'delete',
  },
};

function api() {
  return {
    postDelegatedAuthenticate: (data) =>
      Dispatch(urls.delegatedAuthenticate, data.params, data.payload),
    logout: () => Dispatch(urls.logout, {}, {}),
  };
}

export default api();
