import { action } from 'typesafe-actions';

export const CredentialsActionTypes = {
  GET_LOGS_REQUEST: '@@credentialssations/GET_LOGS_REQUEST',
  GET_DEVICE_LIST_REQUEST: '@@credentialssactions/GET_DEVICE_LIST_REQUEST',
  GET_SECURITY_KEY_LIST_REQUEST:
    '@@credentialssations/GET_SECURITY_KEY_LIST_REQUEST',
  GET_CREDENTIALS_LIST_REQUEST:
    '@@credentialssations/GET_CREDENTIALS_LIST_REQUEST',
  DELETE_CREDENTIAL_REQUEST: '@@credentialssations/DELETE_CREDENTIAL_REQUEST',
  GET_WORKSPACE_CREDENTIAL_LOGS_REQUEST:
    '@@credentialssations/GET_WORKSPACE_CREDENTIAL_LOGS_REQUEST',
  GET_USER_CREDENTIAL_LOGS_REQUEST:
    '@@credentialssations/GET_USER_CREDENTIAL_LOGS_REQUEST',
};

export const getLogsRequest = (data) =>
  action(CredentialsActionTypes.GET_LOGS_REQUEST, data);
export const getDeviceListRequest = (data) =>
  action(CredentialsActionTypes.GET_DEVICE_LIST_REQUEST, data);
export const getSecurityKeyRequest = (data) =>
  action(CredentialsActionTypes.GET_SECURITY_KEY_LIST_REQUEST, data);
export const getCredentialsList = (data) =>
  action(CredentialsActionTypes.GET_CREDENTIALS_LIST_REQUEST, data);
export const deleteCredentials = (data) =>
  action(CredentialsActionTypes.DELETE_CREDENTIAL_REQUEST, data);
export const getWorkspaceCredentialLogsRequest = (data) =>
  action(CredentialsActionTypes.GET_WORKSPACE_CREDENTIAL_LOGS_REQUEST, data);
export const getUserCredentialLogsRequest = (data) =>
  action(CredentialsActionTypes.GET_USER_CREDENTIAL_LOGS_REQUEST, data);
