import { action } from 'typesafe-actions';

export const RdpsActionTypes = {
  GET_RDPS_REQUEST: '@@rdp/GET_RDPS_REQUEST',
  POST_RDPS_REQUEST: '@@rdp/POST_RDPS_REQUEST',
  GET_CHALLENGE_REQUEST: '@@rdp/GET_CHALLENGE_REQUEST',
};

export const getRdpList = (data) =>
  action(RdpsActionTypes.GET_RDPS_REQUEST, data);

export const getChallange = (data) =>
  action(RdpsActionTypes.GET_CHALLENGE_REQUEST, data);

export const postRdp = (data) =>
  action(RdpsActionTypes.POST_RDPS_REQUEST, data);
