// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import credentilasApis from 'apis/credentialsApis';
// actions
import { CredentialsActionTypes } from 'actions/credentialsActions';

export function* handlegetLogs({ payload }) {
  try {
    const response = yield call(credentilasApis.getLogs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetDeviceList({ payload }) {
  try {
    const response = yield call(credentilasApis.getDeviceList, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetSecurityKeys({ payload }) {
  try {
    const response = yield call(credentilasApis.getSecurityKeys, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlegetCredentialsList({ payload }) {
  try {
    const response = yield call(credentilasApis.getCredentialsList, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleDeleteCredentials({ payload }) {
  try {
    const response = yield call(credentilasApis.deleteCredentials, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetWorkspaceCredentialLogs({ payload }) {
  try {
    const response = yield call(credentilasApis.getWorkspaceCredentialLogs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handleGetUserCredentialLogs({ payload }) {
  try {
    const response = yield call(credentilasApis.getUserCredentialLogs, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

/** ***********************************************************
 ************************************************************* */

function* logsSaga() {
  yield all([
    yield takeEvery(CredentialsActionTypes.GET_LOGS_REQUEST, handlegetLogs),
    yield takeEvery(
      CredentialsActionTypes.GET_DEVICE_LIST_REQUEST,
      handleGetDeviceList,
    ),
    yield takeEvery(
      CredentialsActionTypes.GET_SECURITY_KEY_LIST_REQUEST,
      handleGetSecurityKeys,
    ),
    yield takeEvery(
      CredentialsActionTypes.GET_CREDENTIALS_LIST_REQUEST,
      handlegetCredentialsList,
    ),
    yield takeEvery(
      CredentialsActionTypes.DELETE_CREDENTIAL_REQUEST,
      handleDeleteCredentials,
    ),
    yield takeEvery(
      CredentialsActionTypes.GET_WORKSPACE_CREDENTIAL_LOGS_REQUEST,
      handleGetWorkspaceCredentialLogs,
    ),
    yield takeEvery(
      CredentialsActionTypes.GET_USER_CREDENTIAL_LOGS_REQUEST,
      handleGetUserCredentialLogs,
    ),
  ]);
}

export default logsSaga;
