// node modules
import { all, call, takeEvery } from 'redux-saga/effects';
// apis
import rdpApi from 'apis/rdpsApis';
// actions
import { RdpsActionTypes } from 'actions/rdpsAction';

export function* handleGetRdpListdRequest({ payload }) {
  try {
    const response = yield call(rdpApi.getRdpListRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
export function* handleGetChallengeRequest({ payload }) {
  try {
    const response = yield call(rdpApi.getChallangeRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}

export function* handlePostRdpdRequest({ payload }) {
  try {
    const response = yield call(rdpApi.postRdpRequest, payload);
    payload.onSuccessCallback && payload.onSuccessCallback(response);
  } catch (err) {
    payload.onErrorCallback && payload.onErrorCallback();
  }
}
/** ***********************************************************
 ************************************************************* */

function* pinSaga() {
  yield all([
    yield takeEvery(RdpsActionTypes.GET_RDPS_REQUEST, handleGetRdpListdRequest),
    yield takeEvery(RdpsActionTypes.POST_RDPS_REQUEST, handlePostRdpdRequest),
    yield takeEvery(
      RdpsActionTypes.GET_CHALLENGE_REQUEST,
      handleGetChallengeRequest,
    ),
  ]);
}

export default pinSaga;
