import Dispatch from './client';

const urls = {
  getRdps: {
    url: `/administrator/rdp/list`,
    method: 'get',
  },
  getUsersRdps: {
    url: `/administrator/rdp/list/user`,
    method: 'get',
  },
  getChallange: {
    url: '/administrator/rdp/authentication/challenge',
    method: 'get',
  },
  postUsersRdps: (method) => ({
    url: `/administrator/rdp/list/user`,
    method: method || 'post',
  }),
};

function api() {
  return {
    getRdpListRequest: (data) =>
      Dispatch(urls.getRdps, data.params, data.payload),
    getChallangeRequest: (data) =>
      Dispatch(urls.getChallange, data.params, data.payload),
    postRdpRequest: (data) =>
      Dispatch(urls.postUsersRdps(data.method), data.params, data.payload),
  };
}

export default api();
