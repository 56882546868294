import Dispatch from './client';

const apis = {
  getLogs: (id, credentialId) => ({
    url: credentialId
      ? `/administrator/tokens/activity/${id}/credentials/${credentialId}/logs`
      : `/administrator/tokens/activity/${id}/logs`,
    method: 'get',
  }),
  getDeviceList: (userId) => ({
    url: `/administrator/tokens/${userId}/devices`,
    method: 'get',
  }),
  getCredentialsList: (id) => ({
    url: `/administrator/tokens/activity/${id}/credentials`,
    method: 'get',
  }),
  deleteCredentials: (credentialId, userId) => ({
    url: `/administrator/tokens/${userId}/credentials/${credentialId}`,
    method: 'delete',
  }),
  getSecurityKeys: {
    url: `/users/auth/credentials`,
    method: 'get',
  },
  getWorkspaceCredentialLogs: () => ({
    url: `/administrator/tokens/activity/workspace/logs`,
    method: 'get',
  }),
  getUserCredentialLogs: (userId) => ({
    url: `/administrator/tokens/activity/${userId}/activityLogs`,
    method: 'get',
  }),
};

function api() {
  return {
    getLogs: (data) =>
      Dispatch(
        apis.getLogs(data.params.id, data.params.credentialId),
        data.params,
        data.payload,
      ),
    getDeviceList: (data) =>
      Dispatch(apis.getDeviceList(data.params.userId), null, null),
    getCredentialsList: (data) =>
      Dispatch(apis.getCredentialsList(data.id), data.params, data.payload),
    getSecurityKeys: (data) =>
      Dispatch(apis.getSecurityKeys, data.params, data.payload),
    deleteCredentials: (data) =>
      Dispatch(
        apis.deleteCredentials(data.params.credentialId, data.params.userId),
        null,
        null,
      ),
    getWorkspaceCredentialLogs: (data) => {
      return Dispatch(
        apis.getWorkspaceCredentialLogs(),
        data.params,
        data.payload,
      );
    },
    getUserCredentialLogs: (data) => {
      return Dispatch(
        apis.getUserCredentialLogs(data.params.userId),
        data.params,
        data.payload,
      );
    },
  };
}

export default api();
