// node modules
import { all, fork } from 'redux-saga/effects';
// sagas
import authenticationSaga from './authenticationSaga';
import usersSaga from './userSaga';
import adminSaga from './adminSaga';
import customerSaga from './customersSaga';
import credentialsSaga from './credentialsSaga';
import tokenPolicySaga from './tokenPoliciesSaga';
import workspaceSaga from './workspaceSaga';
import trustDomainsSaga from './trustDomainsSaga';
import statisticsSaga from './statisticsSaga';
import securityKeyPolicySaga from './securityKeyPolicySaga';
import namedListsSagas from './namedListsSagas';
import individualSaga from './individualSaga';
import syncSaga from './syncSaga';
import allUsersSaga from './allUsersSaga';
import ssoSaga from './SSOSaga';
import accessPolicySaga from './accessPoliciesSaga';
import groupSaga from './groupSaga';
import tagSaga from './tagsSaga';
import devicesSaga from './devicesSaga';
import fcpDevicesSaga from './fcpDevicesSaga';
import sharedDevicesSaga from './sharedDevicesSaga';
import dashboardStatisticsSaga from './dashboardStatisticsSaga';
import billingSaga from './billingSaga';
import downloadSaga from './downloadSaga';
import organizationUnitsSaga from './organizationUnitsSaga';
import pinSaga from './pinSaga';
import logsSaga from './logsSaga';
import sessoinsSaga from './sessionsSaga';
import rdpSaga from './rdpSaga';

export default function* rootSaga() {
  yield all([
    fork(authenticationSaga),
    fork(usersSaga),
    fork(adminSaga),
    fork(customerSaga),
    fork(credentialsSaga),
    fork(securityKeyPolicySaga),
    fork(namedListsSagas),
    fork(tokenPolicySaga),
    fork(workspaceSaga),
    fork(trustDomainsSaga),
    fork(statisticsSaga),
    fork(individualSaga),
    fork(syncSaga),
    fork(allUsersSaga),
    fork(ssoSaga),
    fork(accessPolicySaga),
    fork(groupSaga),
    fork(tagSaga),
    fork(devicesSaga),
    fork(fcpDevicesSaga),
    fork(sharedDevicesSaga),
    fork(dashboardStatisticsSaga),
    fork(billingSaga),
    fork(downloadSaga),
    fork(organizationUnitsSaga),
    fork(pinSaga),
    fork(logsSaga),
    fork(sessoinsSaga),
    fork(rdpSaga),
  ]);
}
